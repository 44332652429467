import React from "react";
import { graphql } from "gatsby";
import CoverImageTitle from "../components/coverImageTitle";
import DescriptionsPage from "../components/descriptionsPage";

const DatenschutzTemplate = props => {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          background_image: {
            localFile: {
              childImageSharp: { fluid: background_image },
            },
          },
          articles_list,
        },
      },
    },
  } = props;
  return (
    <div className="main-container">
      <CoverImageTitle title={title} fluid={background_image} />
      <DescriptionsPage seconddescription={articles_list} />
      <div style={{ height: "325px", width: "100%" }} />
    </div>
  );
};

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "datenschutz" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      yoast_head
      acf {
        title
        articles_list {
          article_title
          article_content
        }
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        kontakt {
          de_label
          en_label
        }
        produkte {
          de_label
          en_label
        }
        referenzen {
          de_label
          en_label
        }
        sprache {
          de_label
          en_label
        }
        copyright_text
        contact_icons_footer {
          email
          phone
        }
        bottom_footer_right {
          name
          english_name
          url
        }
        bottom_footer_left {
          name
          english_name
          url
        }
      }
    }
  }
`;
export default DatenschutzTemplate;
